import React from 'react';
// Svgs
//import logoOhif from './../../assets/svgs/ohif-logo.svg';

// PNGs
import logoMoksha from './../../assets/svgs/logo-small-mokshadigital.png';

const SVGS = {
  'logo-ohif': logoMoksha,
};

/**
 * Return the matching SVG as a React Component.
 * Results in an inlined SVG Element. If there's no match,
 * return `null`
 */
export default function getSvg(key, props) {
  if (!key || !SVGS[key]) {
    return React.createElement('div', null, 'Missing Image');
  }

  //return React.createElement(SVGS[key], props);

  const imgProps = { ...props, src: SVGS[key], alt: key };
  return React.createElement('img', imgProps);
}

export { SVGS };
